@import '$extensionSrc/sass/consts';

.evenRow {
  background: $lighterGray;
}

.oddRow {
  background: $white;
}

.error {
  color: $red;
}
